<template>
  <div id="app" :class="{paddingBottom: $route.meta.showFoot}">
    <div class="home">
      <template v-if="$route.meta.keepAlive">

        <transition :name="transitionName">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </template>
      <template v-else>
        <transition :name="transitionName">
          <router-view></router-view>
        </transition>
      </template>
    </div>
    <foot v-if="$route.meta.showFoot == true"></foot>
  </div>
</template>

<script>
import foot from '@/components/foot/foot.vue'
import { Dialog } from 'vant';

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    foot
  },
  data(){
    return {
      transitionName: '',
      msgInterval: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        // console.log("slide-right");
        this.transitionName = "slide-right";
      }

      if (to.path == '/login' || to.path == '/forgetPassword') {
        this.msgInterval && clearInterval(this.msgInterval)
      }else {
        this.initInterval()
      }
    }
  },
  mounted(){
    this.initInterval()
  },
  methods: {
    initInterval(){
      if (this.$store.getters["getToken"]) {
        this.msgInterval && clearInterval(this.msgInterval)
        this.msgInterval = setInterval(() => {
          this.getMsgCarousel()
        }, 1000)
      }

    },
    getMsgCarousel(){
      $api.getMsg({
        token: this.$store.getters["getToken"]
      }).then((res) => {
        clearInterval(this.msgInterval)
        Dialog.alert({
          title: res.data.title,
          message: res.data.msg,
        }).then(() => {
          this.initInterval()
        });
      }).catch(err => {
        clearInterval(this.msgInterval)
      })
    }
  },
  beforeDestroy(){},
  destroyed() {
    clearInterval(this.msgInterval)
  },
}
</script>

<style lang="scss">
@import "static/css/public.scss";
@import "static/css/_themes.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100vw;
  height: 100vh;
}
.paddingBottom {
  padding-bottom: 60px;
}
.home {
  height: 100%;
  overflow: auto;
}
</style>

import axios from 'axios' // 引入axios
import qs from 'qs' // 引入qs
// import store from '@/store'
import router from '@/router'
import { Toast } from 'vant';
import store from '@/store'



// axios 默认配置  更多配置查看Axios中文文档
axios.defaults.timeout = 50000 // 超时默认值
// axios.defaults.baseURL = 'http://172.98.60.152/' // 默认baseURL
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/' : 'https://ccss.fjdf123.com'  // 默认baseURL
axios.defaults.responseType = 'json' // 默认数据响应类型
// axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; boundary=----';
axios.defaults.withCredentials = true // 表示跨域请求时是否需要使用凭证

// http request 拦截器
// 在ajax发送之前拦截 比如对所有请求统一添加header token
axios.interceptors.request.use(
  config => {
      // config.headers["Clientid"] = 123123;
      if ( store.getters['getToken']) {
        // config.headers["token"] = store.getters['getToken'];
    }else {
        // router.replace('/login')
    }
    // console.log(config)
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 拦截器
// ajax请求回调之前拦截 对请求返回的信息做统一处理 比如error为401无权限则跳转到登陆界面
axios.interceptors.response.use(
  response => {
      return response
  },
  error => {
    // console.log(error,'error');
    return Promise.reject(error)
  }
)

export default axios // 这句千万不能漏下！！！

/**
 * post 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(data), {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }).then(response => {
          if (response.data.code == 0) {
              resolve(response.data)
              return false;
          }
          if (response.data.code == 401 || response.data.message == "token错误") {
                Toast("登陆过期或未登录")
                router.replace('/login')
                return ;
          }
          Toast(response.data.message)
          reject(response)
      }, err => {
        reject(err)
      })
  })
}

/**
 * get 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: data }, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
      .then(response => {
          if (response.data.code == 0) {
              resolve(response.data)
              return false;
          }
          if (url == '/User/getMsg') {
              return false;
          }
          if (response.data.code == 401 || response.data.message == "token错误") {
              Toast("登陆过期或未登录")
              router.replace('/login')
              return ;
          }
          Toast(response.data.message)
          reject(response)
      }, err => {
          reject(err)
      })
  })
}

/**
 * 其他delete等的封装类似
 * 可以查看中文文档 自行封装
 */
export function upFile (url, data) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            reject(err)
        })
    })
}

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        {
            path: '/login',
            name: 'login',
            component: resolve=>(require(["@/page/login/index.vue"],resolve)),
        },
        {
            path: '/forgetPassword',
            name: 'forgetPassword',
            component: resolve=>(require(["@/page/login/forgetPassword.vue"],resolve)),
        },
        {
            path: '/index',
            name: 'index',
            component: resolve=>(require(["@/page/home/index.vue"],resolve)),
            meta: {
                showFoot: true,
                keepAlive: true
            },
            children: [

            ]
        },
        //收单
        {
            path: '/record',
            name: 'record',
            component: resolve=>(require(["@/page/home/record.vue"],resolve)),
            meta: {
                showFoot: true
            }
        },
        // 抢单
        {
            path: '/membersort',
            name: 'membersort',
            component: resolve=>(require(["@/page/home/membersort.vue"],resolve)),
            meta: {
                showFoot: true
            }
        },
        // 我的
        {
            path: '/user',
            name: 'user',
            component: resolve=>(require(["@/page/home/user.vue"],resolve)),
            meta: {
                showFoot: true
            }
        },
        // index页面的子页面
        {
            path: '/announcement',
            name: 'announcement',
            component:  resolve=>(require(["@/page/index/announcement.vue"],resolve)),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/newdetails',
            name: 'newdetails',
            component: resolve=>(require(["@/page/index/newdetails.vue"],resolve)),
            meta: {
            }
        },
        {
            path: '/platformRewar',
            name: 'platformRewar',
            component: resolve=>(require(["@/page/index/platformRewar.vue"],resolve)),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/beginnerSGuide',
            name: 'beginnerSGuide',
            component: resolve=>(require(["@/page/index/beginnerSGuide.vue"],resolve)),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Information',
            name: 'Information',
            component: resolve=>(require(["@/page/index/Information.vue"],resolve)),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Security',
            name: 'Security',
            component: resolve=>(require(["@/page/index/Security.vue"],resolve)),
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/MoreFeatures',
            name: 'MoreFeatures',
            component: resolve=>(require(["@/page/index/MoreFeatures.vue"],resolve)),
            meta: {
                keepAlive: true
            }
        },
         {
            path: '/leaderboard',
            name: 'leaderboard',
            component: resolve=>(require(["@/page/index/leaderboard.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/inviteFriends',
            name: 'inviteFriends',
            component: resolve=>(require(["@/page/user/inviteFriends.vue"],resolve)),
            meta: {
            }
         },
         {
            path: '/formation',
            name: 'formation',
            component: resolve=>(require(["@/page/user/formation.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/editFormation',
            name: 'editFormation',
            component: resolve=>(require(["@/page/user/editFormation.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/myAssets',
            name: 'myAssets',
            component: resolve=>(require(["@/page/user/myAssets.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/Recharge',
            name: 'Recharge',
            component: resolve=>(require(["@/page/user/Recharge.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/SubmitRecharge',
            name: 'SubmitRecharge',
            component: resolve=>(require(["@/page/user/SubmitRecharge.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/withdraw',
            name: 'withdraw',
            component: resolve=>(require(["@/page/user/withdraw.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/withdrawList',
            name: 'withdrawList',
            component: resolve=>(require(["@/page/user/withdrawList.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/fundLog',
            name: 'fundLog',
            component: resolve=>(require(["@/page/user/fundLog.vue"],resolve)),
            meta: {
            }
         },
         {
            path: '/tuandui',
            name: 'tuandui',
            component: resolve=>(require(["@/page/user/tuandui.vue"],resolve)),
            meta: {
            }
         },
        {
            path: '/AddCreditCard',
            name: 'AddCreditCard',
            component: resolve=>(require(["@/page/user/AddCreditCard.vue"],resolve)),
            meta: {
            }
         },
         {
            path: '/passwordManagement',
            name: 'passwordManagement',
            component: resolve=>(require(["@/page/user/passwordManagement.vue"],resolve)),
            meta: {
            }
          },
         {
            path: '/Settingsa',
            name: 'Settingsa',
            component: resolve=>(require(["@/page/user/Settingsa.vue"],resolve)),
            meta: {
            }
          },
        {
            path: '/changePassword',
            name: 'changePassword',
            component: resolve=>(require(["@/page/user/changePassword.vue"],resolve)),
            meta: {
            }
          },
         {
            path: '/withdrawPassword',
            name: 'withdrawPassword',
            component: resolve=>(require(["@/page/user/withdrawPassword.vue"],resolve)),
            meta: {
            }
          },
        {
            path: '/editTheme',
            name: 'editTheme',
            component: resolve=>(require(["@/page/user/editTheme.vue"],resolve)),
            meta: {
            }
          },



    ]
})


<template>
  <div class="foot">
      <div class="tab-item" v-for="(item,index) in tab_list" :key="index" @click="clickTab(index, item.path)" :class="{zoom: index == 2, active: tab_index == index}">
        <img class="icon" :src="tab_index == index ? item.icon_active : item.icon" alt="">
        <p class="text">{{item.text}}</p>
      </div>
  </div>
</template>

<script>
export default {
  name: "foot",
  props: {},
  components: {},
  data() {
    return {
      tab_index: 0,
      tab_list: [
        {
            text: "首页",
            icon: require("/static/img/index.png"),
            icon_active: require("/static/img/index_active.png"),
            path: '/index'
        },
        {
          text: "收单",
          icon: require("/static/img/record.png"),
          icon_active: require("/static/img/record_active.png"),
          path: '/record'
        },
        {
          text: "抢单",
          icon: require("/static/img/bar-order.png"),
          icon_active: require("/static/img/bar-order.png"),
          path: '/membersort'
        },
        {
          text: "客服",
          icon: require("/static/img/chat.png"),
          icon_active: require("/static/img/chat.png"),
          path: 'www.baidu.com'
        },
        {
          text: "我的",
          icon: require("/static/img/user.png"),
          icon_active: require("/static/img/user_active.png"),
          path: '/user'
        }
      ],
    }
  },
  mounted(){
    this.tab_index = this.$store.getters['getFootIndex']
  },
  methods: {
    clickTab(index, path){
      if (index == 3) {
        window.location.href = this.$store.getters['getKefuUrl']
        return;
      }
       this.tab_index = index;
       this.$store.commit('setFootIndex', index)
       this.$router.push({
         path: path
       })
    }
  }
}
</script>

<style scoped lang="scss">
.foot {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 0 26px;
  box-sizing: border-box;
  .tab-item {
      font-size: 12px;
      .icon {
        width: 22px;
        height: 22px;
      }

      &.zoom {
        margin-top: -40px;
        background: #fff;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        border-top: 1px solid #f0f0f0;
        .icon {
          width: 34px;
          height: 34px;
          //margin-bottom: 6px;
        }
        .text {
          color: #ffbc37;
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

  }
  .active {
    color: #506eff;
  }
}
</style>
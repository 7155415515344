import {post, get, upFile} from '@/axios/index'

export function login (options) {
    return post('/Login/checkLogin', options)
}
export function register (options) {
    return post('/Login/register', options)
}

export function index (options) {
    return get('/index/index', options)
}
// 公告
export function gonggao (options) {
    return get('/Index/gonggao', options)
}
// 公告详情
export function newdetails (options) {
    return get('/Index/newdetails', options)
}
// 平台奖励
export function jiangli (options) {
    return get('/Index/jiangli', options)
}
// 新手指引
export function xinshou (options) {
    return get('/Index/xinshou', options)
}
// 咨询
export function zixun (options) {
    return get('/Index/zixun', options)
}
// 安全保障
export function anquan (options) {
    return get('/Index/anquan', options)
}
// 更多
export function more (options) {
    return get('/Index/more', options)
}
// 抢单
export function submit_order (options) {
    return post('/Index/submit_order', options)
}
export function todayzhanguo (options) {
    return get('/index/todayzhanguo', options)
}
export function shoudan (options) {
    return get('/index/shoudan', options)
}
//返利列表
export function fanyonglist (options) {
    return get('/index/fanyonglist', options)
}
// 收单列表
export function orderlist (options) {
    return get('/Index/orderlist', options)
}

// 抢单-获取会员等级
export function getuservip (options) {
    return post('/User/getuservip', options)
}

// 排行榜
export function paihangbang (options) {
    return get('/User/paihangbang', options)
}
// 用户信息
export function userInfo (options) {
    return get('/User/index', options)
}
// 邀请好友
export function sharecode (options) {
    return get('/User/sharecode', options)
}
// 修改昵称
export function set_info (options) {
    return post('/User/set_info', options)
}
// 修改手机号
export function set_info_account (options) {
    return post('/User/set_info_account', options)
}
export function zichan (options) {
    return get('/User/zichan', options)
}
// 获取提现账户信息
export function bankInfo (options) {
    return get('/User/bankInfo', options)
}
// 提现
export function drawup (options) {
    return post('/Withdraw/drawup', options)
}
// 提现列表
export function WithdrawIndex (options) {
    return get('/Withdraw/index', options)
}

// 提交充值记录
export function bank_rc (options) {
    return post('/Recharge/bank_rc', options)
}
// 获取充值记录
export function chongzhijilu (options) {
    return get('/Recharge/chongzhijilu', options)
}
// 资金日志
export function bill (options) {
    return get('/User/bill', options)
}
// 我的团队
export function getMyTeam (options) {
    return get('/sockjs-node/info', options)
}
// 提交银行卡
export function tjyhk (options) {
    return post('/User/tjyhk', options)
}
// 获取密码
export function password (options) {
    return get('/User/password', options)
}
// 修改提现密码
export function set_password1 (options) {
    return post('/User/set_password1', options)
}
// 获取信用卡
export function xinxi_credit_score (options) {
    return get('/User/xinxi_credit_score', options)
}
export function order_and_commission (options) {
    return get('/User/order_and_commission', options)
}

/**
 * 轮询调用，code返回0停止轮询弹出提示框，用户点击确定之后继续轮询
 * code返回0表示有未读消息
 * code返回-1表示没有未读消息
 */
export function getMsg (options) {
    return get('/User/getMsg', options)
}
// 我的团队
export function teamdets (options) {
    return get('/User/teamdets', options)
}
// 获取用户名
export function getUserName (options) {
    return get('/User/xinxi', options)
}
// 获取头像
export function getAvatarUrl (options) {
    return get('/User/xinxi_photo', options)
}
// 获取手机号
export function getAccount (options) {
    return get('/User/xinxi_account', options)
}
// 上传图片
export function Uploads_img (options) {
    return upFile('/User/Uploads_img', options)
}
export function set_info_photo (options) {
    return post('/User/set_info_photo', options)
}
// 重置密码
export function setpsw (options) {
    return post('/Login/setpsw', options)
}
// 忘记密码获取验证码
export function password_send_sms_codes (options) {
    return post('/Login/password_send_sms_codes', options)
}
// 修改提现密码获取验证码
export function password1_send_sms_code (options) {
    return post('/Login/password1_send_sms_code', options)
}






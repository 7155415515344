import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

let state = {
    token: '',
    kefu_url: '',
    footIndex: 0,   //底部导航index
    themeIndex: 0,   // 主题颜色
    userInfo: {
        username: '',
        photo: '',     // 头像
        account: '',   // 手机号
        u_yqm: '',     // 邀请码
        level_name: 1,  // 等级
        paixu_id: '',
    }
}

export default new Vuex.Store({
    state,
    action: {},
    mutations: {
        setToken(state, token) {
            state.token = token;
            window.localStorage.setItem('USERTOKEN', token)
        },
        setFootIndex(state, footIndex) {
            state.footIndex = footIndex;
            window.localStorage.setItem('FOOTINDEX', footIndex)
        },
        setThemeIndex(state, themeIndex) {
            state.themeIndex = themeIndex;
            window.localStorage.setItem('THEMEINDEX', themeIndex)
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
            window.localStorage.setItem('USERINFO', userInfo)
        },
        // 存储客服跳转链接
        setKefuUrl(state, url){
            state.kefu_url = url;
            window.localStorage.setItem('KEFUURL', url)
        },


    },
    getters: {
        getToken(state) {
            state.token = window.localStorage.getItem('USERTOKEN')
            return state.token
        },
        getFootIndex(state) {
            state.footIndex = window.localStorage.getItem('FOOTINDEX')
            return state.footIndex
        },
        getThemeIndex(state) {
            state.themeIndex = window.localStorage.getItem('THEMEINDEX')
            return state.themeIndex
        },
        getUserInfo(state) {
            state.userInfo = window.localStorage.getItem('USERINFO')
            return state.userInfo
        },
        getKefuUrl(state){
            state.kefu_url = window.localStorage.getItem('KEFUURL');
            return state.kefu_url
        }
    }
})